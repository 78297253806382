import React from "react"
import { Modal, Typography, Box, Button } from "@mui/material"
import Timer from "./TimerRun"
import { LoadingButton } from "@mui/lab"

const TimeOutWarning = ({
    handlelogOut,
    open,
    handleContinueSession,
    isLoading
}) => {
    const localValueMethod = async () => {
        setTimeout(async () => {
            await handleContinueSession()
        }, 900)
    }

    return (
        <Modal open={open}>
            <Box
                style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: "#FFFFFF",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 600,
                    borderRadius: "8px",
                    padding: "20px"
                }}
            >
                <Typography
                    variant="h2"
                    sx={{ fontWeight: 600, mb: 1, color: "#444 !important" }}
                >
                    Your session is about to expire
                </Typography>
                <Typography
                    variant="p"
                    sx={{
                        color: "#707070",
                        mb: 2
                    }}
                >
                    You will be signed out automatically if you do not resume
                    your session within next{" "}
                    <span style={{ fontWeight: 600 }}>
                        {<Timer handlelogOut={handlelogOut} />}
                    </span>{" "}
                    Seconds.
                </Typography>

                <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
                    <Button
                        onClick={handlelogOut}
                        variant="text"
                        color="brand"
                        sx={{ borderRadius: 16, mr: 1 }}
                    >
                        Sign out
                    </Button>
                    <LoadingButton
                        onClick={localValueMethod}
                        disabled={isLoading}
                        color="brand"
                        variant="contained"
                        loading={isLoading}
                        sx={{
                            borderRadius: 16
                        }}
                    >
                        Resume session
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}
export default TimeOutWarning
