import axios from "axios"
import { toast } from "react-toastify"
import moment from "moment"
import { reactRouterUtility } from "../constant/helpersUtility"
import { jwtDecode } from "jwt-decode"
import { clearLocalStorageExceptPersistRoot } from "../constant/ClearLocalStorage"
const apiHost = process.env.REACT_APP_BASE_URL
const productApiHost = process.env.REACT_APP_PRODUCT_BASE_URL
const domainHost = process.env.REACT_APP_DOMAIN_NAME
const selfOnboardHost = "https://gaia.kairoswealth.com"

//const apiHost = process.env.REACT_APP_BASE_URL;
//const productApiHost = process.env.REACT_APP_PRODUCT_BASE_URL;
//https://3jm3vyie94.execute-api.ap-southeast-1.amazonaws.com/helix-test-products-survey/
//https://qzk5eqpue7.execute-api.ap-southeast-1.amazonaws.com/helix-test
// const apiHost = 'https://qzk5eqpue7.execute-api.ap-southeast-1.amazonaws.com/helix-test';
// const productApiHost = 'https://3jm3vyie94.execute-api.ap-southeast-1.amazonaws.com/helix-test-products-survey';

const selfOnboardAxiosConfig = {
    baseURL: selfOnboardHost,
    headers: {
        env: domainHost
    }
}

const selfOnboardKycAxiosConfig = {
    baseURL: apiHost
}

const publicSelfOnboardAxios = axios.create(selfOnboardAxiosConfig)
const privateSelfOnboardAxios = axios.create(selfOnboardAxiosConfig)
const publicSelfOnboardProspectAxios = axios.create(selfOnboardKycAxiosConfig)

const Axios = axios.create({ baseURL: apiHost })
const Axios1 = axios.create({ baseURL: productApiHost })
const Axios0 = axios.create({ baseURL: apiHost })

let isFirstTime = false
let failedAPIs = []

const excludedUrls = [
    "/api/v1/user_rights/update-user-rights",
    "/api/v1/prospect/add-prospect-kyc",
    "/api/v1/prospect/add-bank-details",
    "/api/v1/client/update-client-asset-details",
    "/api/v1/prospect/update-asset-details",
    "/api/v1/client/add-client-bank-details",
    "api/v1/client/update-client-kyc",
    "/api/v1/productservice/upload-file",
    "/api/v1/productservice/upload-file-edit",
    "/api/v1/direct-deal/add-direct-deal-document",
    "/api/v1/direct-deal/delete-direct-deal-metric",
    "/api/v1/direct-deal/delete-direct-deal-investor",
    "/api/v1/direct-deal/delete-direct-deal-member",
    "/api/v1/direct-deal/delete-direct-deal-document",
    "/api/v1/direct-deal/delete-direct-deal",
    "/api/v1/direct-deal/update-direct-deal",
    "/api/v1/direct-deal/submit-deal-for-approval",
    "/api/v1/direct-deal/approve-or-reject-deal",
    "/api/v1/direct-deal/invite-to-private-deal",
    "api/v1/client/add-client-related-parties",
    "api/v1/client/add-client-related-parties-attachment",
    "api/v1/client/delete-client-related-parties",
    "api/v1/client/delete-client-related-parties-attachment",
    "/api/v1/commonservice/save-audit",
    "/api/v1/direct-deal/close-deal"
]

// ** Selfonbarod interceptors
privateSelfOnboardAxios.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem("user"))
        const accessToken = user?.idToken

        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`
        }
        return config
    },
    (error) => Promise.reject(error)
)

publicSelfOnboardProspectAxios.interceptors.request.use(
    (config) => {
        const data = JSON.parse(localStorage.getItem("self-onboard-data"))

        if (data?.token) {
            config.headers["self-registration-token"] = data.token
        }
        console.log(config)
        return config
    },
    (error) => Promise.reject(error)
)

Axios.interceptors.request.use(async (req) => {
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user") //|| DataUser;
        if (user) {
            req.headers.Authorization = `Bearer ${JSON.parse(user).idToken}`
            if (
                ["PUT", "POST", "DELETE"].includes(req.method.toUpperCase()) &&
                !excludedUrls.some((url) => req?.url?.startsWith(url))
            ) {
                // Check if payload has entityId property
                if (!req.data?.entityId) {
                    // Assuming the payload is an object, you can customize this based on localstorage value
                    req.data = {
                        ...req.data,
                        entityId:
                            (await JSON.parse(localStorage.getItem("user"))
                                ?.userTypeId?.description) === "Kairos Admin" &&
                            (atob(localStorage.getItem("entityId")) ===
                                "null" ||
                                atob(localStorage.getItem("entityId")) ===
                                    "undefined" ||
                                atob(localStorage.getItem("entityId")) ===
                                    null ||
                                atob(localStorage.getItem("entityId")) ===
                                    undefined)
                                ? "0"
                                : atob(localStorage.getItem("entityId"))
                    }
                }
            }
            // Check if the request method is GET and has query parameters
            if (req.method.toUpperCase() === "GET" || req.params) {
                // Assuming entityId is the key you want to bind to the header
                req.headers["userentity-id"] =
                    (await JSON.parse(localStorage.getItem("user"))?.userTypeId
                        ?.description) === "Kairos Admin" &&
                    (atob(localStorage.getItem("entityId")) === "null" ||
                        atob(localStorage.getItem("entityId")) ===
                            "undefined" ||
                        atob(localStorage.getItem("entityId")) === null ||
                        atob(localStorage.getItem("entityId")) === undefined)
                        ? "0"
                        : atob(localStorage.getItem("entityId"))
            }
        }
    }
    return req
})

Axios.interceptors.response.use(
    (response) => {
        if (response?.status?.code === 401) {
            if (
                response.request.responseURL.includes(
                    "/api/v1/admin/user-logout"
                )
            ) {
                clearLocalStorageExceptPersistRoot()
            } else {
                toast.error(response.data.statusMessage)
            }
        } else if (response?.status?.code !== 200) {
            return response
        }
        return response
    },
    async (error) => {
        if (error?.response?.status === 401) {
            handleCognitoSessionExpire(error, "Axios")
        }
        return error
    }
)

Axios0.interceptors.request.use((req) => {
    return req
})

Axios0.interceptors.response.use((response) => {
    return response
})

Axios1.interceptors.request.use(async (req) => {
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user") //|| DataUser;
        if (user) {
            req.headers.Authorization = `Bearer ${JSON.parse(user).idToken}`
            if (
                ["PUT", "POST", "DELETE"].includes(req.method.toUpperCase()) &&
                !excludedUrls.some((url) => req?.url?.startsWith(url))
            ) {
                // Check if payload has entityId property
                if (!req.data?.entityId) {
                    // Assuming the payload is an object, you can customize this based on your payload structure
                    req.data = {
                        ...req.data,
                        entityId:
                            (await JSON.parse(localStorage.getItem("user"))
                                ?.userTypeId?.description) === "Kairos Admin" &&
                            (atob(localStorage.getItem("entityId")) ===
                                "null" ||
                                atob(localStorage.getItem("entityId")) ===
                                    "undefined" ||
                                atob(localStorage.getItem("entityId")) ===
                                    null ||
                                atob(localStorage.getItem("entityId")) ===
                                    undefined)
                                ? "0"
                                : atob(localStorage.getItem("entityId"))
                    }
                }
            }
            if (req.method.toUpperCase() === "GET" || req.params) {
                // Assuming entityId is the key you want to bind to the header
                req.headers["userentity-id"] =
                    (await JSON.parse(localStorage.getItem("user"))?.userTypeId
                        ?.description) === "Kairos Admin" &&
                    (atob(localStorage.getItem("entityId")) === "null" ||
                        atob(localStorage.getItem("entityId")) ===
                            "undefined" ||
                        atob(localStorage.getItem("entityId")) === null ||
                        atob(localStorage.getItem("entityId")) === undefined)
                        ? "0"
                        : atob(localStorage.getItem("entityId"))
            }
        }
    }
    return req
})

Axios1.interceptors.response.use(
    (response) => {
        if (response?.status?.code === 401) {
            if (
                response.request.responseURL.includes(
                    "/api/v1/admin/user-logout"
                )
            ) {
                clearLocalStorageExceptPersistRoot()
            } else {
                toast.error(response.data.statusMessage)
            }
        } else if (response?.status?.code !== 200) {
            return response
        }
        return response
    },
    async (error) => {
        if (error?.response?.status === 401) {
            handleCognitoSessionExpire(error, "Axios1")
        }
    }
)

const handleCognitoSessionExpire = async (error, triggerPoint) => {
    let userObj = JSON.parse(window.localStorage.getItem("user"))
    sessionStorage.setItem("initial", "1")
    const decodedToken = jwtDecode(userObj?.idToken)
    const refreshToken = decodedToken
    const currentTime = moment(new Date())
    let minutes =
        decodedToken && refreshToken?.exp
            ? moment
                  .duration(
                      moment(new Date(refreshToken?.exp)).diff(currentTime)
                  )
                  .asMinutes()
            : null

    if (
        minutes > 15 ||
        (!isFirstTime && refreshToken === null) ||
        (!isFirstTime && refreshToken && minutes < 0)
    ) {
        isFirstTime = true
        const res = await getRefreshToken(userObj)

        if (res?.data?.status === true) {
            userObj.idToken = res?.data?.body?.idToken
            userObj.accessToken = res?.data?.body?.accessToken
            let temp2 = {
                ...error.config,
                data: error?.config?.data
                    ? JSON.parse(error?.config?.data)
                    : error?.config?.data
            }
            const originalRequest = temp2
            originalRequest.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`
            window.localStorage.setItem("user", JSON.stringify(userObj))
            window.localStorage.setItem("entityId", btoa(userObj?.entityId))
            isFirstTime = false

            failedAPIs?.length > 0 &&
                failedAPIs.forEach((req) => {
                    req.headers.Authorization = `Bearer ${res?.data?.body?.idToken}`
                })
            let AxiosAPIList =
                failedAPIs?.length > 0
                    ? failedAPIs.map((req) =>
                          triggerPoint === "Axios" ? Axios(req) : Axios1(req)
                      )
                    : []

            AxiosAPIList?.push(
                triggerPoint === "Axios"
                    ? Axios(originalRequest)
                    : triggerPoint === "Axios1"
                      ? Axios1(originalRequest)
                      : null
            )

            AxiosAPIList?.length > 0 &&
                Promise.all(AxiosAPIList).then((res) => {
                    for (let i = 0; i < res?.length; i++) {
                        if (res[i].data?.status) {
                            if (
                                res?.length === 1 &&
                                (res[0].config.url ===
                                    "/api/v1/admin/getall-notification-bg" ||
                                    res[0].config.url ===
                                        "/api/v1/productservice/upload-file" ||
                                    res[0].config.url ===
                                        "/api/v1/productservice/download-file" ||
                                    res[0].config.url ===
                                        "/api/v1/productservice/delete-file")
                            ) {
                                console.log("errors")
                            } else {
                                toast.success(res[i]?.data?.statusMessage)
                            }
                        } else {
                            toast.error(res[i]?.data?.errorMessage)
                        }
                    }
                    setTimeout(() => {
                        if (
                            res?.length === 1 &&
                            (res[0].config.url ===
                                "/api/v1/admin/getall-notification-bg" ||
                                res[0].config.url ===
                                    "/api/v1/productservice/upload-file" ||
                                res[0].config.url ===
                                    "/api/v1/productservice/download-file" ||
                                res[0].config.url ===
                                    "/api/v1/productservice/delete-file")
                        ) {
                            console.log("errors")
                        } else {
                            reactRouterUtility.navigate(0)
                        }
                    }, 100)
                })
        } else {
            toast.warning("Sorry for the inconvience, please try again. ")
            // localStorage.clear();
            clearLocalStorageExceptPersistRoot()
            window.location.reload()
        }
    } else if (minutes > 0 && minutes < 15) {
        let userObjRef = JSON.parse(window.localStorage.getItem("user"))
        isFirstTime = false
        failedAPIs = []
        let temp1 = {
            ...error.config,
            data: error?.config?.data
                ? JSON.parse(error?.config?.data)
                : error?.config?.data
        }
        const originalRequest1 = temp1
        originalRequest1.headers.Authorization = `Bearer ${userObjRef?.idToken}`
        return triggerPoint === "Axios"
            ? Axios(originalRequest1)
            : triggerPoint === "Axios1"
              ? Axios1(originalRequest1)
              : null
    } else if (isFirstTime) {
        let temp = {
            ...error.config,
            data: error?.config?.data
                ? JSON.parse(error?.config?.data)
                : error?.config?.data
        }
        const ApiRequests = temp
        failedAPIs.push(ApiRequests)
    }
}

export const getRefreshToken = async (userObj) => {
    if (userObj && Object.keys(userObj)?.length > 0) {
        let requestObj = {
            userId: userObj?.userId,
            refreshToken: userObj?.refreshToken
        }
        const response = await axios.post(
            `${apiHost}/api/v1/common/refresh-token`,
            requestObj
        )
        return response
    }
}

export {
    Axios,
    Axios1,
    Axios0,
    publicSelfOnboardAxios,
    privateSelfOnboardAxios,
    publicSelfOnboardProspectAxios
}
