import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { clearLocalStorageExceptPersistRoot } from "../../constant/ClearLocalStorage"
import { logIn } from "services"
import { navigateToDashboard } from "./authHelper"

export const login = createAsyncThunk(
    "auth/login",
    async ({ formvalues, navigate, onError, onSuccess, email }) => {
        try {
            const response = await logIn(formvalues)
            if (response.data.status) {
                // if mfa enabled, we get the statusMessage as EMAIL_OTP
                if (response.data.statusMessage === "EMAIL_OTP") {
                    // ** If mfa session missing, redirect to login
                    if (!response.data.body.mfaSession) {
                        onError("Mfa session missing")
                        navigate("/login")
                        return
                    }

                    onSuccess(
                        "Your verification code has been sent to your registered email."
                    )
                    navigate("/mfa-auth", {
                        state: {
                            email,
                            mfaSession: response.data.body.mfaSession
                        }
                    })
                    return response.data.body
                }

                // ** If not mfa enabled
                const userInfo = response.data.body
                navigateToDashboard(userInfo)
                onSuccess("You've successfully logged in!")
                return response.data.body
            } else {
                onError(`${response?.data?.errorMessage}`)
                if (response?.data?.body?.resetPasswordLink) {
                    window.open(response?.data?.body?.resetPasswordLink)
                } else {
                    navigate(`/login`)
                }
            }
        } catch (err) {
            if (err?.response?.status === 404) {
                console.error("error", err)
            } else if (err?.response?.status === 401) {
                onError("Invalid Username/Password")
            } else if (
                err.response.status !== 200 &&
                err.response.status !== 201
            ) {
                onError(err.response.data.message)
                navigate(`/`)
            } else {
                return err.response.data.errorMessage
            }
        }
    }
)

const storeInLocalStorage = ({ key, value }) => {
    localStorage.setItem(key, value)
}
const updateStore = ({ state, payload }) => {
    state.user = payload
    if (state.user?.idToken) {
        storeInLocalStorage({
            key: "user",
            value: JSON.stringify({ ...payload })
        })
        storeInLocalStorage({ key: "entityId", value: btoa(payload?.entityId) })
        storeInLocalStorage({
            key: "familyOfficeType",
            value: payload?.familyOfficeType?.description
        })
        storeInLocalStorage({
            key: "userColumnChooser",
            value: JSON.stringify(payload?.columnList?.userManagement || [])
        })

        storeInLocalStorage({
            key: "CampaignHistoryColumnChooser",
            value: JSON.stringify(payload?.columnList?.campaignHistory || [])
        })
        storeInLocalStorage({
            key: "CampaignColumnChooser",
            value: JSON.stringify(payload?.columnList?.ongoingCampaign || [])
        })

        storeInLocalStorage({
            key: "ClientColumnChooser",
            value: JSON.stringify(payload?.columnList?.clients || [])
        })
        storeInLocalStorage({
            key: "feedbackColumnChooser",
            value: JSON.stringify(payload?.columnList?.customerFeedback || [])
        })

        storeInLocalStorage({
            key: "columnslocal",
            value: payload?.columnList?.vendorManagement
        })

        storeInLocalStorage({
            key: "columnsLocalProducts",
            value: payload?.columnList?.financialProducts
        })

        storeInLocalStorage({
            key: "serviceColumnChooser",
            value: JSON.stringify(payload?.columnList?.financialServices || [])
        })
        storeInLocalStorage({
            key: "enquiryColumnChooser",
            value: JSON.stringify(payload?.columnList?.enquires || [])
        })
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        error: "",
        loading: false
    },
    reducers: {
        setAuth: (state, action) => {
            // ** for  mfa flow
            updateStore({ state, payload: action.payload })
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setLogout: (state) => {
            clearLocalStorageExceptPersistRoot()
            state.user = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
            updateStore({ state, payload: action.payload })
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.message
        })
    }
})

export const { setUser, setLogout, setAuth } = authSlice.actions

export default authSlice.reducer
